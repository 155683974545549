<template>
  <div class="container">
    <!-- 店务系统数据 -->
    <div class="shop_sys_container" v-loading="shopSysLoading">
      <span>从店务系统查</span>
      <el-form
        ref="shopSysQueryFormRef"
        :model="shopSysQueryParams"
        size="small"
        :inline="true"
        :rules="{
          phonenum: {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur',
          },
        }"
      >
        <el-form-item label="顾客手机号" prop="phonenum">
          <el-input
            v-model.trim="shopSysQueryParams.phonenum"
            placeholder="请输入顾客手机号"
            type="number"
            clearable
            maxlength="15"
            @keyup.enter="handleShopSysQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            @click="handleShopSysQuery"
            icon="el-icon-search"
            type="primary"
            >查询</el-button
          >
          <el-button @click="resetShopSysQuery" icon="el-icon-refresh"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <span v-if="!shopSysList.length">暂无数据</span>
      <div
        v-for="(member, index) in shopSysList"
        :key="index"
        class="shop_member_box"
      >
        <el-row :gutter="10">
          <el-col :span="4">
            <span>
              <span class="label">会员姓名：</span>
              <span class="value">{{ member.nickName }}</span>
            </span>
          </el-col>
          <el-col :span="4">
            <span>
              <span class="label">会员电话：</span>
              <span class="value">{{ member.memberPhone }}</span>
            </span>
          </el-col>
          <el-col :span="6">
            <span>
              <span class="label">店务系统会员ID：</span>
              <span class="value">{{ member.memberId }}</span>
            </span>
          </el-col>
          <el-col :span="4">
            <span>
              <span class="label">所属门店：</span>
              <span class="value">{{ member.shopName }}</span>
            </span>
          </el-col>
          <el-col :span="6">
            <span>
              <span class="label">最近一次消费门店：</span>
              <span class="value">{{ member.lastConsumeShopName }}</span>
            </span>
          </el-col>
        </el-row>
        <span
          >这个会员ID是否已注册小程序：<span class="value">{{
            member.isWxUser ? "注册了" : "没有注册"
          }}</span></span
        >
        <div
          v-for="(wxUser, index) in member.wxInfoList"
          :key="index"
          class="wx_user_box"
        >
          <el-row :gutter="10">
            <el-col :span="4">
              <span
                ><span class="label">小程序会员昵称：</span
                ><span class="value">{{ wxUser.nickName }}</span></span
              >
            </el-col>
            <el-col :span="4">
              <span
                ><span class="label">UserPhone：</span
                ><span class="value">{{ wxUser.userPhone }}</span></span
              >
            </el-col>
            <el-col :span="6">
              <span
                ><span class="label">MemberPhone：</span
                ><span class="value">{{ wxUser.memberPhone }}</span></span
              >
            </el-col>
            <el-col :span="10">
              <span
                ><span class="label">小程序最后登录时间：</span
                ><span class="value">{{
                  dateFormatter(wxUser.lastLoginTime)
                }}</span></span
              >
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <span
                ><span class="label">这个微信是否关注公众号：</span
                ><span class="value">{{
                  wxUser.isSubscribe ? "关注了" : "没有关注"
                }}</span></span
              >
            </el-col>
            <el-col :span="18">
              <span
                ><span class="label">公众号关注时间：</span
                ><span class="value">{{
                  dateFormatter(wxUser.subscribeTime)
                }}</span></span
              >
            </el-col>
          </el-row>
          <span>消息推送记录：</span>
          <el-table
            :data="member.pushInfoList"
            border
            stripe
            style="width: 100%"
          >
            <el-table-column label="批次号" prop="batchId" width="160" />
            <el-table-column
              label="推送时间"
              prop="pushTime"
              width="210"
              :formatter="(row) => dateFormatter(row.pushTime)"
            />
            <el-table-column
              label="推送内容"
              prop="items"
              show-overflow-tooltip
            />
            <el-table-column label="顾客是否确认" prop="isConfirm" width="120">
              <template #default="scope">
                {{ scope.row.isConfirm ? "已确认" : "未确认" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 会员小程序系统数据 -->
    <div class="vip_sys_container" v-loading="vipSysLoading">
      <span>从小程序系统查</span>
      <el-form
        ref="vipSysQueryFormRef"
        :model="vipSysQueryParams"
        size="small"
        :inline="true"
        :rules="{
          phonenum: {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur',
          },
        }"
      >
        <el-form-item label="顾客登录小程序的手机号" prop="phonenum">
          <el-input
            v-model.trim="vipSysQueryParams.phonenum"
            placeholder="请输入顾客登录小程序所使用的手机号"
            type="number"
            clearable
            maxlength="15"
            @keyup.enter="handleVipSysQuery"
            style="width: 280px"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            @click="handleVipSysQuery"
            icon="el-icon-search"
            type="primary"
            >查询</el-button
          >
          <el-button @click="resetVipSysQuery" icon="el-icon-refresh"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <span v-if="!vipSysList.length">暂无数据</span>
      <div
        v-for="(wxUser, index) in vipSysList"
        :key="index"
        class="vip_member_box"
      >
        <el-row :gutter="10">
          <el-col :span="4">
            <span
              ><span class="label">小程序会员昵称：</span
              ><span class="value">{{ wxUser.nickName }}</span></span
            >
          </el-col>
          <el-col :span="4">
            <span
              ><span class="label">UserPhone：</span
              ><span class="value">{{ wxUser.userPhone }}</span></span
            >
          </el-col>
          <el-col :span="6">
            <span
              ><span class="label">MemberPhone：</span
              ><span class="value">{{ wxUser.memberPhone }}</span></span
            >
          </el-col>
          <el-col :span="10">
            <span
              ><span class="label">小程序最后登录时间：</span
              ><span class="value">{{
                dateFormatter(wxUser.lastLoginTime)
              }}</span></span
            >
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <span
              ><span class="label">这个微信绑定的店务系统会员ID：</span
              ><span class="value">{{ wxUser.memberId }}</span></span
            >
          </el-col>
          <el-col :span="12">
            <span
              ><span class="label">CRM中的店务系统会员ID：</span
              ><span class="value">{{ wxUser.crmMemberId }}</span></span
            >
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <span
              ><span class="label">这个微信是否关注公众号：</span
              ><span class="value">{{
                wxUser.isSubscribe ? "关注了" : "没有关注"
              }}</span></span
            >
          </el-col>
          <el-col :span="18">
            <span
              ><span class="label">公众号关注时间：</span
              ><span class="value">{{
                dateFormatter(wxUser.subscribeTime)
              }}</span></span
            >
          </el-col>
        </el-row>
        <span>消息推送记录：</span>
        <el-table :data="wxUser.pushInfoList" border stripe style="width: 100%">
          <el-table-column label="批次号" prop="batchId" width="160" />
          <el-table-column
            label="推送时间"
            prop="pushTime"
            width="210"
            :formatter="(row) => dateFormatter(row.pushTime)"
          />
          <el-table-column
            label="推送内容"
            prop="items"
            show-overflow-tooltip
          />
          <el-table-column label="顾客是否确认" prop="isConfirm" width="120">
            <template #default="scope">
              {{ scope.row.isConfirm ? "已确认" : "未确认" }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup lang="js">
import { onMounted, reactive, ref } from 'vue';
import { getShopSysMemberInfoList, getVipSysMemberInfoList } from '@/http/member';
import { ElMessage } from 'element-plus';
import { formatDate } from '@/utils/dateUtils';
import { throttle } from 'lodash'

/** 运维-会员关系查询 */
defineOptions({ name: 'ops-membership'});
// export default {
//   name: "ops-membership", // 运维-会员关系查询

// };
// 店务系统查询参数
const shopSysQueryParams = reactive({
    phonenum: null,
})
const shopSysQueryFormRef = ref()
const shopSysLoading = ref(false)
const shopSysList = ref([])
const resetShopSysQuery = () => {
    shopSysQueryParams.phonenum = null
    shopSysList.value = []
    shopSysQueryFormRef.value.resetFields()
    // handleShopSysQuery()
}

const handleShopSysQuery = throttle(function() {
    getShopSysList()
}, 2000)

const getShopSysList = async () => {
    if (!shopSysQueryFormRef.value) {
        return
    }
    if (!await shopSysQueryFormRef.value.validate()) {
        return
    }
    shopSysLoading.value = true
    try {
        let res = await getShopSysMemberInfoList(shopSysQueryParams)
        // console.log('====shop: ', res)
        shopSysList.value = res
        shopSysLoading.value = false
    } catch (e) {
        shopSysLoading.value = false
        ElMessage.error(e || '查询失败')
    }
}

const vipSysQueryParams = reactive({
    phonenum: null,
})
const vipSysQueryFormRef = ref()
const vipSysLoading = ref(false)
const vipSysList = ref([])
const resetVipSysQuery = () => {
    vipSysQueryParams.phonenum = null
    vipSysList.value = []
    vipSysQueryFormRef.value.resetFields()
    // handleVipSysQuery()
}

const handleVipSysQuery = throttle(function() {
    getVipSysList()
}, 2000)

const getVipSysList = async () => {
    if (!vipSysQueryFormRef.value) {
        return
    }
    if (!await vipSysQueryFormRef.value.validate()) {
        return
    }
    vipSysLoading.value = true
    try {
        let res = await getVipSysMemberInfoList(vipSysQueryParams)
        vipSysList.value = res
        vipSysLoading.value = false
    } catch (e) {
        vipSysLoading.value = false
        ElMessage.error(e || '查询失败')
    }
}

/** 格式化日期 */
const dateFormatter = (row) => {
    return formatDate(row)
    // return formatDate(row.pushTime)
}

// 初始化
onMounted(() => {

})
</script>

<style lang="scss" scoped>
.container {
  .shop_sys_container {
    border: 1px solid #ccc;
    padding: 10px;
    .shop_member_box {
      border: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .wx_user_box {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .vip_sys_container {
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 10px;
    .vip_member_box {
      border: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .label {
    color: #666;
  }
  .value {
    color: #000;
    font-weight: 500;
  }
}
</style>
