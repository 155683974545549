import request from "./index";

// 获取客户详情
export function memberDetail(data) {
  return request.get(`/app/info/customerconsulting/GetMemberBaseInfo`, {
    params: data,
  });
  // return request({
  //   url: "https://testadmin.skin79.com.cn/api/consumer/member/memberInfo",
  //   method: "post",
  //   data: params,
  // });
}

// 客户持卡列表
export function memberCardList(data) {
  return request.get(`/app/info/customerconsulting/GetMemberCardList`, {
    params: data,
  });
}

// 消耗记录
export function consumeRecord(data) {
  return request.get(`/app/info/customerconsulting/GetMemberConsume`, {
    params: data,
  });
}

/** 根据客户手机号查询店务会员 */
export function getShopSysMemberInfoList(data) {
  return request.get("/app/info/wxuserinfo/getMemberByMemberPhone", {
    params: data,
  });
}

/** 根据客户手机号查询小程序系统会员 */
export function getVipSysMemberInfoList(data) {
  return request.get("/app/info/wxuserinfo/getMemberByUserPhone", {
    params: data,
  });
}
